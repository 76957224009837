// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-charges-and-fees-js": () => import("./../../../src/pages/about/charges-and-fees.js" /* webpackChunkName: "component---src-pages-about-charges-and-fees-js" */),
  "component---src-pages-about-complaints-procedure-js": () => import("./../../../src/pages/about/complaints-procedure.js" /* webpackChunkName: "component---src-pages-about-complaints-procedure-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-about-our-team-js": () => import("./../../../src/pages/about/our-team.js" /* webpackChunkName: "component---src-pages-about-our-team-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-services-civil-litigation-js": () => import("./../../../src/pages/services/civil-litigation.js" /* webpackChunkName: "component---src-pages-services-civil-litigation-js" */),
  "component---src-pages-services-criminal-law-js": () => import("./../../../src/pages/services/criminal-law.js" /* webpackChunkName: "component---src-pages-services-criminal-law-js" */),
  "component---src-pages-services-family-law-js": () => import("./../../../src/pages/services/family-law.js" /* webpackChunkName: "component---src-pages-services-family-law-js" */),
  "component---src-pages-services-immigration-js": () => import("./../../../src/pages/services/immigration.js" /* webpackChunkName: "component---src-pages-services-immigration-js" */),
  "component---src-pages-services-index-js": () => import("./../../../src/pages/services/index.js" /* webpackChunkName: "component---src-pages-services-index-js" */),
  "component---src-pages-services-personal-injury-js": () => import("./../../../src/pages/services/personal-injury.js" /* webpackChunkName: "component---src-pages-services-personal-injury-js" */),
  "component---src-pages-services-wills-in-islam-js": () => import("./../../../src/pages/services/wills-in-islam.js" /* webpackChunkName: "component---src-pages-services-wills-in-islam-js" */),
  "component---src-pages-site-map-js": () => import("./../../../src/pages/site-map.js" /* webpackChunkName: "component---src-pages-site-map-js" */)
}

